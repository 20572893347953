body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8f8f8;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
    display: grid;
    grid-template-areas: "a";
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

button {
    font-size: 2em;
    border: 0;
    padding: 0;
    background: none;
    cursor: pointer;
}

#flex-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
}

#flex-container > .flex-item {
    -webkit-flex: auto;
    flex: auto;
}


.card {
    margin: 10px;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e9ebec;
    border-radius: 0.25rem;
}

.card-title {
    margin-bottom: 0.5rem;
}

.card-content {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem 1.25rem;
}

h4, .h4 {
    font-size: calc(1.25625rem + 0.075vw);
}

@media (min-width: 1200px) {
    h4, .h4 {
        font-size: 1.3125rem;
    }
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.fw-bold {
    font-weight: 600 !important;
}

.pr-2 {
    padding-left: 0.5rem !important;
}

.display-4 {
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .display-4 {
        font-size: 3.5rem;
    }
}

.text-primary {
    color: #5a162f !important;
}

.mt-4, .my-4 {
    margin-top: 1.5rem;
}
.mb-4, .mx4 {
    margin-bottom: 1.5rem;
}
.mt-5, .my-5 {
    margin-top: 3rem!important;
}
.mb-5, .mx-5 {
    margin-bottom: 3rem!important;
}

h5, .h5 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 18px;
}